<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :action-label="actionLabel"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
      >
        <template #cell(isPlan)="{ item }">
          <div
            :class="
              item.isPlan && item.isPlan === 'สำเร็จ'
                ? 'text-success'
                : 'text-danger'
            "
          >
            {{ item.isPlan }}
          </div>
        </template>

        <template #cell(trasactionNumber)="{ item }">
          <div :class="item.hasTransaction ? 'text-success' : ''">
            {{ item.trasactionNumber }}
          </div>
        </template>

        <template #cell(seeDetail)="{ item }">
          <div v-if="seeDetail" class="text-center">
            <b-spinner
              v-if="printingItemId && printingItemId == item.id"
              small
              variant="primary"
              label="printing..."
            ></b-spinner>

            <a v-else href="javascript:void(0)" @click="onSeeDetail(item)">
              <font-awesome-icon icon="file-alt" />
            </a>

            <generate-certificate
              v-if="isReport && printItem && printItem.id === item.id"
              :ref="`pdf_${item.id}`"
              :item="{
                ...printItem,
                number: `ใบประกาศหลังเรียนจบ ${item.school} ${item.classRoom} ${item.yearTerm}.pdf`,
              }"
              :format="format"
              :type-select="typePrint"
              :user-name="userNamePrint"
              :user-rank="userRankPrint"
              :director-username="directorUsernamePrint"
              :director-rank="directorRankPrint"
              :director-prefix="directorPrefixPrint"
              :user-name2="userNamePrint2"
              :user-rank2="userRankPrint2"
              :title_name="title_name"
              :title_name2="title_name2"
              :title_name3="title_name3"
              @onProgressDone="onProgressDone"
            ></generate-certificate>

            <generate-certificate-old-version
              v-if="isReport && printItem && printItem.id === item.id"
              :ref="`pdf_${item.id}_old`"
              :item="{
                ...printItem,
                number: `ใบประกาศหลังเรียนจบ ${item.school} ${item.classRoom} ${item.yearTerm}.pdf`,
              }"
              :format="format"
              :type-select="typePrint"
              :user-name="userNamePrint"
              :user-rank="userRankPrint"
              :director-username="directorUsernamePrint"
              :director-rank="directorRankPrint"
              :director-prefix="directorPrefixPrint"
              :user-name2="userNamePrint2"
              :user-rank2="userRankPrint2"
              :title_name="title_name"
              :title_name2="title_name2"
              :title_name3="title_name3"
              @onProgressDone="onProgressDone"
            ></generate-certificate-old-version>

            <generate-certificate2-person
              v-if="isReport && printItem && printItem.id === item.id"
              :ref="`pdf_${item.id}_2_person`"
              :item="{
                ...printItem,
                number: `ใบประกาศหลังเรียนจบ ${item.school} ${item.classRoom} ${item.yearTerm}.pdf`,
              }"
              :format="format"
              :type-select="typePrint"
              :user-name="userNamePrint"
              :user-rank="userRankPrint"
              :director-username="directorUsernamePrint"
              :director-rank="directorRankPrint"
              :director-prefix="directorPrefixPrint"
              :user-name2="userNamePrint2"
              :user-rank2="userRankPrint2"
              :title_name="title_name"
              :title_name2="title_name2"
              :title_name3="title_name3"
              @onProgressDone="onProgressDone"
            ></generate-certificate2-person>

            <GenerateCertificateNopicVue
              v-if="isReport && printItem && printItem.id === item.id"
              :ref="`pdf_${item.id}_nopic`"
              :item="{
                ...printItem,
                number: `ใบประกาศหลังเรียนจบ ${item.school} ${item.classRoom} ${item.yearTerm}.pdf`,
              }"
              :format="format"
              :type-select="typePrint"
              :user-name="userNamePrint"
              :user-rank="userRankPrint"
              :director-username="directorUsernamePrint"
              :director-rank="directorRankPrint"
              :director-prefix="directorPrefixPrint"
              :user-name2="userNamePrint2"
              :user-rank2="userRankPrint2"
              :title_name="title_name"
              :title_name2="title_name2"
              :title_name3="title_name3"
              @onProgressDone="onProgressDone"
            ></GenerateCertificateNopicVue>

            <GenerateCertificateNopica4Vue
              v-if="isReport && printItem && printItem.id === item.id"
              :ref="`pdf_${item.id}_nopica4`"
              :item="{
                ...printItem,
                number: `ใบประกาศหลังเรียนจบ ${item.school} ${item.classRoom} ${item.yearTerm}.pdf`,
              }"
              :format="format"
              :type-select="typePrint"
              :user-name="userNamePrint"
              :user-rank="userRankPrint"
              :director-username="directorUsernamePrint"
              :director-rank="directorRankPrint"
              :director-prefix="directorPrefixPrint"
              :user-name2="userNamePrint2"
              :user-rank2="userRankPrint2"
              :title_name="title_name"
              :title_name2="title_name2"
              :title_name3="title_name3"
              @onProgressDone="onProgressDone"
            ></GenerateCertificateNopica4Vue>

            <select-type-print-certificate
              v-if="isReport && printItem && printItem.id === item.id"
              :item="printItem"
              @selected:type="onTypeSelected"
              @onHidden="onProgressDone"
              v-model="showModalSelectCertificate"
            ></select-type-print-certificate>
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <div v-if="showAction" class="text-center">
            <b-button size="sm" @click="editTeaching(item)">{{
              actionButtonText
            }}</b-button>
          </div>
        </template>

        <template #cell(survey)="{ item }">
          <div class="text-center">
            <b-button size="sm" variant="primary" @click="seeQrCode(item)"
              >แบบประเมิน</b-button
            >
          </div>
        </template>

        <template #cell(refresh)="{ item }">
          <div v-if="showDelete" class="text-center">
            <b-button variant="danger" size="sm" @click="deleteTeaching(item)"
              >ลบ</b-button
            >
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, MRank, TeachingDare } from "../../models";
import BaseTable from "./Base";
import GenerateCertificate from "../report/GenerateCertificate";
import GenerateCertificate2Person from "../report/GenerateCertificate2Person";
import GenerateCertificateOldVersion from "../report/GenerateCertificateOldVersion";
import SelectTypePrintCertificate from "../modal/SelectTypePrintCertificate";
import GenerateCertificateNopicVue from "../report/GenerateCertificateNopic.vue";
import GenerateCertificateNopica4Vue from "../report/GenerateCertificateNopica4.vue";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "เพิ่ม/แก้ไข",
    },
    actionLabel: {
      type: String,
      default: "เพิ่ม/แก้ไขข้อมูล",
    },
    seeDetail: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    isReport: {
      type: Boolean,
      default: false,
    },
    survey: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BaseTable,
    GenerateCertificate,
    GenerateCertificateOldVersion,
    SelectTypePrintCertificate,
    GenerateCertificate2Person,
    GenerateCertificateNopicVue,
    GenerateCertificateNopica4Vue,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      format: "",
      sortBy: "",
      sortDesc: false,
      printItem: {},
      printingItemId: null,
      typePrint: null,
      userNamePrint: "",
      userRankPrint: "",
      directorUsernamePrint: "",
      directorPrefixPrint: "",
      directorRankPrint: "",
      userNamePrint2: "",
      userRankPrint2: "",
      title_name: "",
      title_name2: "",
      title_name3: "",
      showModalSelectCertificate: false,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onProgressDone() {
      this.printingItemId = null;
    },

    onParamsChanged() {
      // console.log("1 111");
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        params = {},
        additionalParams = {},
        numberOfPages = 1;

      this.isFetching = true;

      try {
        await MRank.api().findAll();

        if (!ctx.downloadAll) {
          this.$set(params, "limit", ctx.perPage);
          this.$set(params, "offset", (ctx.currentPage - 1) * ctx.perPage);
        }

        if (this.params && this.params.userProfileId) {
          this.$set(
            additionalParams,
            "userProfileId",
            this.params.userProfileId
          );
        }

        if (this.params && this.params.mHeadquarterId) {
          this.$set(
            additionalParams,
            "mHeadquarterId",
            this.params.mHeadquarterId
          );
        }

        if (this.params && this.params.mDivisionId) {
          this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
        }

        if (this.params && this.params.mStationId) {
          this.$set(additionalParams, "mStationId", this.params.mStationId);
        }

        if (this.params && this.params.mSchoolId) {
          this.$set(additionalParams, "mSchoolId", this.params.mSchoolId);
        }

        if (this.params && this.params.keyword) {
          this.$set(additionalParams, "keyword", this.params.keyword);
        }

        if (this.params && this.params.fiscalYearFrom) {
          this.$set(
            additionalParams,
            "fiscalYearFrom",
            this.params.fiscalYearFrom
          );
        }

        if (this.params && this.params.termFrom) {
          this.$set(additionalParams, "termFrom", this.params.termFrom);
        }

        if (this.params && this.params.fiscalYearTo) {
          this.$set(additionalParams, "fiscalYearTo", this.params.fiscalYearTo);
        }

        if (this.params && this.params.termTo) {
          this.$set(additionalParams, "termTo", this.params.termTo);
        }

        if (this.params && this.params.isPlan) {
          this.$set(additionalParams, "isPlan", this.params.isPlan);
        }

        if (this.params && this.params.startDate) {
          this.$set(additionalParams, "startDate", this.params.startDate);
        }

        if (this.params && this.params.endDate) {
          this.$set(additionalParams, "endDate", this.params.endDate);
        }

        if (this.params && this.params.mTransactionTypeId) {
          this.$set(
            additionalParams,
            "mTransactionTypeId",
            this.params.mTransactionTypeId
          );
        }

        if (
          this.params &&
          this.params.hasTransaction !== undefined &&
          this.params.hasTransaction !== null
        ) {
          this.$set(
            additionalParams,
            "hasTransaction",
            this.params.hasTransaction
          );
        }

        if (this.sortBy) {
          this.$set(additionalParams, "order", this.sortBy);
          this.$set(
            additionalParams,
            "order_by",
            this.sortDesc ? "DESC" : "ASC"
          );
        }

        const promise = await TeachingDare.api().findAll(
          { ...params, ...additionalParams },
          {
            save: false,
          }
        );

        let { data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        data = responseData.map((record) => {
          let user_name = "";

          if (record.user_profile) {
            const userRank = MRank.find(record.user_profile.mRankId);

            user_name = `${record.user_profile.first_name} ${record.user_profile.last_name}`;

            if (userRank) {
              user_name = `${userRank.m_rank_name} ${user_name}`;
            }
          }

          let yearTerm = record.fiscal_year || "";

          if (record.term) {
            yearTerm += `/${record.term}`;
          }

          let classRoom = "";

          if (record.m_class) {
            classRoom += record.m_class.m_class_name;

            const roomNumber = parseInt(record.room);

            if (!isNaN(roomNumber)) {
              classRoom += `/${roomNumber}`;
            }
          }

          let isPlan = "ไม่สำเร็จ";

          if (record.isPlan && record.isPlan === "ทำแผนการสอน") {
            isPlan = "สำเร็จ";
          }

          return {
            ...record,
            plan_student: record.plan_student || 0,
            actual_student: record.actual_student || 0,
            // teaching_plan_count: record.teaching_plan_count || 0,
            teaching_plan_count: "13",
            room: record.room || "-",
            fiscal_year: record.fiscal_year || "-",
            class: record.m_class ? record.m_class.m_class_name : null,
            classRoom,
            yearTerm,
            school: record.m_school ? record.m_school.m_school_name : null,
            station: record.m_station ? record.m_station.m_station_name : null,
            amphur:
              record.m_school && record.m_school.m_amphur
                ? record.m_school.m_amphur.m_amphur_name
                : null,
            province:
              record.m_school && record.m_school.m_province
                ? record.m_school.m_province.m_province_name
                : null,
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
            isPlan,
            hasTransaction: record.transactionId !== null,
            transactionNumber: record.transaction
              ? record.transaction.number
              : "-",
            transactionTypeName:
              record.transaction && record.transaction.m_transaction_type
                ? record.transaction.m_transaction_type.m_transaction_name
                : "-",
            user_name,
          };
        });

        if (this.isReport) {
          const reportPromise = await TeachingDare.api().getIssueCertificate({
            ...additionalParams,
          });

          const { data } = reportPromise.response;

          if (data.response_status == "SUCCESS") {
            this.$emit("reportIssueCert", data);
          }
        }

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      if (!ctx.downloadAll) {
        this.numberOfPages = numberOfPages;
      }

      return data;
    },

    async onSeeDetail(item) {
      this.$emit("seeDetail", item);

      this.printingItemId = item.id;

      if (this.isReport) {
        // console.log(item);
        const promise = await TeachingDare.api().findOne(
          item.id,
          {},
          { save: false }
        );

        if (promise) {
          const { data = {} } = promise.response.data;

          this.printItem = { ...data };

          this.$nextTick(() => {
            this.showModalSelectCertificate = true;
            // this.$bvModal.show(
            //   `select-type-create-certificate-modal_${item.id}`
            // );

            // if (this.$refs[`pdf_${item.id}`]) {
            //   this.$nextTick(() => {
            //     const { $refs } = this.$refs[`pdf_${item.id}`];

            //     $refs.html2Pdf.generatePdf();
            //   });
            // }
          });
        } else {
          this.$toast.error(
            "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      }
    },

    async onTypeSelected(
      typePrint,
      user_name,
      user_rank,
      director_username,
      director_prefix,
      director_rank,
      user_name2,
      user_rank2,
      selectedCertSize,
      title_name,
      title_name2,
      title_name3
    ) {
      this.typePrint = typePrint;
      this.userNamePrint = user_name;
      this.userRankPrint = user_rank;
      this.userNamePrint2 = user_name2;
      this.userRankPrint2 = user_rank2;
      this.directorUsernamePrint = director_username;
      this.directorPrefixPrint = director_prefix;
      this.directorRankPrint = director_rank;
      this.title_name = title_name;
      this.title_name2 = title_name2;
      this.title_name3 = title_name3;

      // console.log(selectedCertSize);
      if (selectedCertSize == "แบบเก่า") {
        this.$nextTick(() => {
          if (this.$refs[`pdf_${this.printItem.id}_old`]) {
            this.$nextTick(() => {
              // const { $refs } = this.$refs[`pdf_${this.printItem.id}`];
              this.$refs[`pdf_${this.printItem.id}_old`].generateReport();
            });
          }
        });
      } else if (selectedCertSize == "ผู้ลงนาม 2 คน") {
        this.$nextTick(() => {
          if (this.$refs[`pdf_${this.printItem.id}_2_person`]) {
            this.$nextTick(() => {
              // const { $refs } = this.$refs[`pdf_${this.printItem.id}`];
              this.$refs[`pdf_${this.printItem.id}_2_person`].generateReport();
            });
          }
        });
      } else if (selectedCertSize == "ข้อความเท่านั้น") {
        this.$nextTick(() => {
          if (this.$refs[`pdf_${this.printItem.id}_nopic`]) {
            this.$nextTick(() => {
              // const { $refs } = this.$refs[`pdf_${this.printItem.id}`];
              this.$refs[`pdf_${this.printItem.id}_nopic`].generateReport();
            });
          }
        });
      } else if (selectedCertSize == "ข้อความเท่านั้น (A4)") {
        this.$nextTick(() => {
          if (this.$refs[`pdf_${this.printItem.id}_nopica4`]) {
            this.$nextTick(() => {
              // const { $refs } = this.$refs[`pdf_${this.printItem.id}`];
              this.$refs[`pdf_${this.printItem.id}_nopica4`].generateReport();
            });
          }
        });
      } else {
        this.$nextTick(() => {
          if (this.$refs[`pdf_${this.printItem.id}`]) {
            this.$nextTick(() => {
              // const { $refs } = this.$refs[`pdf_${this.printItem.id}`];
              this.$refs[`pdf_${this.printItem.id}`].generateReport();
            });
          }
        });
      }

      if (this.printItem.logIssueCertificated == null) {
        let promise = await TeachingDare.api().updateIssueCertificate(
          this.printItem.id,
          {
            docType: selectedCertSize,
          }
        );

        if (promise.response.response_status == "SUCCESS") {
          this.$refs.table.refresh();
        }
      }
    },

    editTeaching(item) {
      this.$emit("edit", item);
    },

    seeQrCode(item) {
      this.$emit("seeQrCode", item);
    },

    deleteTeaching(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
