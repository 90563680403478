<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1440"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="927px;"
      pdf-content-height="636px;"
      ref="html2Pdf"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      @hasDownloaded="hasDownloaded($event)"
      :html-to-pdf-options="htmlToPdfOptions"
    >
      <section slot="pdf-content">
        <!-- นักเรียน -->
        <div
          :id="`cert-student_${item.id}`"
          v-if="item && item.mapping_teaching_students.length > 0"
        >
          <section
            v-for="student in item.mapping_teaching_students.slice(0, 1)"
            :key="`student_${student.student_dare.id}`"
            :id="`student_${student.student_dare.id}`"
            style="position: relative; width: 1170px; height: 720px"
            class="html2pdf__page-break"
          >
            <div :style="getStyle(bgImgSrc)">
              <!-- <p class="ft00 text-center mt-5">
              <b>สำนักงานตำรวจแห่งชาติ</b>
            </p> -->
              <!-- <p class="ft02 text-center">
              <b>และ</b>
            </p> -->
              <p class="ft01" style="padding-top: 150px; padding-left: 500px">
                <b>
                  {{
                    item.m_school.m_school_name
                      ? item.m_school.m_school_name
                      : "........................."
                  }}</b
                >
              </p>
              <!-- <p class="ft02 text-center">
              <b>ขอมอบประกาศนียบัตรนี้ให้แก่</b>
            </p> -->
              <p class="ft01" style="padding-top: 70px; padding-left: 500px">
                <b>
                  {{
                    student.student_dare.m_prefix
                      ? student.student_dare.m_prefix.m_prefix_name
                      : ""
                  }}
                  {{
                    student.student_dare.first_name &&
                    student.student_dare.last_name
                      ? `${student.student_dare.first_name} ${student.student_dare.last_name}`
                      : "..............................................................."
                  }}</b
                >
              </p>
              <p class="ft01" style="padding-top: 250px; padding-left: 500px">
                <b>
                  {{ dateNow ? dateNow : "" }}
                  {{ dateYear ? dateYear : "" }}</b
                >
              </p>

              <div class="container">
                <div class="column" style="">
                  <p class="ft01" style="margin-top: 20px">
                    {{
                      title_name
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                  <p class="ft01" style="margin-top: 20px">
                    ( {{ userName }} )
                  </p>
                  <p class="ft01" style="margin-top: 10px">{{ userRank }}</p>
                </div>
                <div class="column">
                  <!-- <p class="ft01" style="margin-top: 20px;"> {{ userName2 }}</p>
                <br /> -->
                  <br />
                  <!-- <p class="ft01" style="margin-top: 40px;">  {{ userName2 }} </p>
                <p class="ft01" style="margin-top: 10px;"> {{ userRank2 }}</p> -->
                </div>
                <div class="column" style="margin-right: -10px">
                  <p class="ft01" style="margin-top: 20px">
                    {{
                      title_name3
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                  <!-- <br /> -->
                  <!-- <br /> -->
                  <p class="ft01" style="margin-top: 20px">
                    ( {{ directorUsername }} )
                  </p>
                  <p class="ft01" style="margin-top: 10px">
                    {{ directorRank }}
                  </p>
                </div>
              </div>

              <!-- <p class="ft01" style="margin-top: -10px; padding-left: 50px">
                <b>
                  หมายเลขใบประกาศ:
                  {{
                    item.user_profile.model_no
                      ? `${item.user_profile.model_no.padStart(3, "0")}00${(
                          index +
                          1 +
                          ""
                        ).padStart(5, "0")}`
                      : `00000${(index + 1 + "").padStart(5, "0")}`
                  }}</b
                >
              </p> -->
            </div>
          </section>
        </div>
        <!-- นักเรียน -->

        <!-- โรงเรียน -->
        <section
          :id="`cert-school_${item.id}`"
          style="position: relative; width: 1170px; height: 710px"
          class="html2pdf__page-break"
        >
          <div :style="getStyle(bgImgSrc)">
            <p class="ft01" style="padding-top: 150px; padding-left: 500px">
              <b>
                {{
                  item.m_school.m_school_name
                    ? item.m_school.m_school_name
                    : "........................."
                }}</b
              >
            </p>
            <!-- <p class="ft02 text-center">
              <b>ขอมอบประกาศนียบัตรนี้ให้แก่</b>
            </p> -->
            <p class="ft01" style="padding-top: 70px; padding-left: 500px">
              <b>
                โรงเรียน{{
                  item.m_school.m_school_name
                    ? item.m_school.m_school_name
                    : "........................."
                }}
              </b>
            </p>
            <p class="ft01" style="padding-top: 250px; padding-left: 500px">
              <b>
                {{ dateNow ? dateNow : "" }}
                {{ dateYear ? dateYear : "" }}</b
              >
            </p>

            <div class="container">
              <div class="column" style="">
                <p class="ft01" style="margin-top: 25px">
                  {{
                    title_name
                  }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p class="ft01" style="margin-top: 20px">( {{ userName }} )</p>
                <p class="ft01" style="margin-top: 10px">{{ userRank }}</p>
              </div>
              <div class="column">
                <!-- <p class="ft01" style="margin-top: 20px;"> {{ userName2 }}</p>
                <br /> -->
                <br />
                <!-- <p class="ft01" style="margin-top: 40px;">  {{ userName2 }} </p>
                <p class="ft01" style="margin-top: 10px;"> {{ userRank2 }}</p> -->
              </div>
              <div class="column" style="margin-right: -10px">
                <p class="ft01" style="margin-top: 25px">
                  {{
                    title_name3
                  }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <!-- <br /> -->
                <!-- <br /> -->
                <p class="ft01" style="margin-top: 20px">
                  ( {{ directorUsername }} )
                </p>
                <p class="ft01" style="margin-top: 10px">{{ directorRank }}</p>
              </div>
            </div>
          </div>
        </section>
        <!-- โรงเรียน -->

        <!-- ครู -->
        <div
          :id="`cert-teacher_${item.id}`"
          v-if="item && item.teacher_dares.length > 0"
        >
          <section
            v-for="teacher in item.teacher_dares.slice(0, 1)"
            :key="`teacher_${teacher.id}`"
            :id="`teacher_${teacher.id}`"
            style="position: relative; width: 1170px; height: 720px"
            class="html2pdf__page-break"
          >
            <div :style="getStyle(bgImgSrc)">
              <p class="ft01" style="padding-top: 150px; padding-left: 500px">
                <b>
                  {{
                    item.m_school.m_school_name
                      ? item.m_school.m_school_name
                      : "........................."
                  }}</b
                >
              </p>
              <!-- <p class="ft02 text-center">
              <b>ขอมอบประกาศนียบัตรนี้ให้แก่</b>
            </p> -->
              <p class="ft01" style="padding-top: 70px; padding-left: 500px">
                <b
                  >{{ teacher.m_prefix ? teacher.m_prefix.m_prefix_name : "" }}
                  {{
                    teacher.first_name && teacher.last_name
                      ? `${teacher.first_name} ${teacher.last_name} ครูประจำชั้น`
                      : "......................................................... ครูประจำชั้น"
                  }}</b
                >
              </p>
              <p class="ft01" style="padding-top: 250px; padding-left: 500px">
                <b>
                  {{ dateNow ? dateNow : "" }}
                  {{ dateYear ? dateYear : "" }}</b
                >
              </p>

              <div class="container">
                <div class="column" style="">
                  <p class="ft01" style="margin-top: 25px">
                    {{
                      title_name
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                  <p class="ft01" style="margin-top: 20px">
                    ( {{ userName }} )
                  </p>
                  <p class="ft01" style="margin-top: 10px">{{ userRank }}</p>
                </div>
                <div class="column">
                  <!-- <p class="ft01" style="margin-top: 20px;"> {{ userName2 }}</p>
                <br /> -->
                  <br />
                  <!-- <p class="ft01" style="margin-top: 40px;">  {{ userName2 }} </p>
                <p class="ft01" style="margin-top: 10px;"> {{ userRank2 }}</p> -->
                </div>
                <div class="column" style="margin-right: -10px">
                  <p class="ft01" style="margin-top: 25px">
                    {{
                      title_name3
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                  <!-- <br /> -->
                  <!-- <br /> -->
                  <p class="ft01" style="margin-top: 20px">
                    ( {{ directorUsername }} )
                  </p>
                  <p class="ft01" style="margin-top: 10px">
                    {{ directorRank }}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!-- ครู -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { MPrefix } from "../../models";

export default {
  props: {
    item: Object,
    format: String,
    typeSelect: String,
    userName: String,
    userRank: String,
    directorUsername: String,
    directorRank: String,
    directorPrefix: String,
    userName2: String,
    userRank2: String,
    title_name: String,
    title_name2: String,
    title_name3: String,
  },

  data() {
    return {
      bgImgSrc: "",
    };
  },

  components: {
    VueHtml2pdf,
  },

  computed: {
    htmlToPdfOptions() {
      return {
        filename: `${this.item.number}`,
        margin: [2, 0, 1, 0],
        image: {
          type: "jpeg",
          quality: 0.95,
        },
        jsPDF: {
          format: "a4",
          orientation: "landscape",
        },
        html2canvas: {
          scale: 1,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          allowTaint: true,
        },
      };
    },

    dateNow() {
      let thaiNumbers = {
        0: "๐",
        1: "๑",
        2: "๒",
        3: "๓",
        4: "๔",
        5: "๕",
        6: "๖",
        7: "๗",
        8: "๘",
        9: "๙",
      };

      let date = new Date()
        .toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
        .split(" ");

      // Function to convert Arabic numerals to Thai numerals
      const convertToThaiNumerals = (str) =>
        str.replace(/\d/g, (digit) => thaiNumbers[digit]);

      return `${convertToThaiNumerals(date[0])} ${date[1]}`;
    },

    dateYear() {
      let thaiNumbers = {
        0: "๐",
        1: "๑",
        2: "๒",
        3: "๓",
        4: "๔",
        5: "๕",
        6: "๖",
        7: "๗",
        8: "๘",
        9: "๙",
      };

      let date = new Date()
        .toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
        .split(" ");

      // Convert the year to Thai numerals
      const convertToThaiNumerals = (str) =>
        str.replace(/\d/g, (digit) => thaiNumbers[digit]);

      return convertToThaiNumerals(date[2]);
    },

    prefixStation() {
      let prefix = "ภูธร";

      if (
        this.item &&
        this.item.m_station &&
        this.item.m_station.m_division &&
        this.item.m_station.m_division.m_headquarter &&
        this.item.m_station.m_division.m_headquarter.m_headquarters_name ===
          "บช.น."
      ) {
        prefix = "นครบาล";
      }

      return prefix;
    },
  },

  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    prefixs(id) {
      return MPrefix.find(id);
    },

    getStyle(image) {
      return {
        position: "relative",
        width: "1120px",
        height: "765px",
        backgroundImage: `url('${image}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
      };
    },

    onProgress(progress) {
      if (progress === 100) {
        this.$emit("onProgressDone");
      }
    },
    hasStartedGeneration() {
      this.$emit("hasStartedGeneration");
    },
    hasGenerated() {
      this.$emit("hasGenerated");
    },
    hasDownloaded() {
      this.$emit("hasDownloaded");
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  flex: 20%;
  height: 200px;
  padding: 20px;
  margin: 5px;
  text-align: center;
}

.columnLeft {
  flex: 20%;
  height: 200px;
  padding: 20px;
  margin: 5px;
  text-align: left;
}

.container {
  display: flex;
  padding-left: 30px;
  padding-right: 100px;
}

p {
  margin: 0;
  padding: 0;
}

.ft00 {
  font-size: 48px;
  font-family: ABCDEE + 2, Bold;
  color: #000000;
}

.ft01 {
  font-size: 26px;
  font-family: ABCDEE + 2, Bold;
  color: #000;
}

.ft02 {
  font-size: 28px;
  font-family: ABCDEE + 2, Bold;
  color: #000;
}

.ft03 {
  font-size: 18px;
  font-family: ABCDEE + 2, Bold;
  color: #000;
}

.dis_row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dis_col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
